import { useState } from "react";

import { i18nConfig } from "@/utils/config";
import { appStorageService } from "@/services";

import { ClickAwayListener, Grow, ListItemIcon, Popper } from "@mui/material";
import AppIconButton from "@/components/AppIconButton";
import AppImage from "@/components/AppImage";
import AppMenuList from "@/components/AppMenuList";
import AppMenuItem from "@/components/AppMenuItem";
import AppPaper from "@/components/AppPaper";
import AppListItemText from "@/components/AppListItemText";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

const LanguageMenu = () => {
  const [languageMenuPopperAnchorEl, setLanguageMenuPopperAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const languageMenuPopperOpen = !!languageMenuPopperAnchorEl;

  const { i18n } = useTranslation();

  const router = useRouter();

  const flagImgSrc =
    i18nConfig.localeToConfigMap[i18n.language]?.imageSrc ?? "";

  const handleLanguageMenuPopperToggle = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setLanguageMenuPopperAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLanguageMenuPopperAnchorEl(null);
  };

  const handleLanguageChange = (locale: string) => () => {
    router.push(router.asPath, undefined, { locale });
    window.NextPublic.lang = locale as any;
    appStorageService.saveCookieLocale(locale);
    setLanguageMenuPopperAnchorEl(null);
  };

  return (
    <>
      <AppIconButton
        borderRadius="circular"
        onClick={handleLanguageMenuPopperToggle}
      >
        <AppImage
          src={flagImgSrc}
          alt="language flag"
          width={500}
          height={500}
          sx={{
            width: 24,
            height: 24,
          }}
        />
      </AppIconButton>
      <Popper
        open={languageMenuPopperOpen}
        anchorEl={languageMenuPopperAnchorEl}
        placement="bottom-end"
        transition
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 10 + 2],
            },
          },
        ]}
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "top right" }}>
            <AppPaper boxShadowVariant="menuPopper">
              <ClickAwayListener onClickAway={handleClose}>
                <AppMenuList>
                  {i18nConfig.localeConfigs.map((localeConfig) => (
                    <AppMenuItem
                      key={localeConfig.label}
                      onClick={handleLanguageChange(localeConfig.locale)}
                      sx={{ textTransform: "capitalize" }}
                    >
                      <ListItemIcon>
                        <AppImage
                          src={localeConfig.imageSrc}
                          alt={`${localeConfig.label} - language flag`}
                          width={500}
                          height={500}
                          sx={{
                            width: 24,
                            height: 24,
                          }}
                        />
                      </ListItemIcon>
                      <AppListItemText>{localeConfig.label}</AppListItemText>
                    </AppMenuItem>
                  ))}
                </AppMenuList>
              </ClickAwayListener>
            </AppPaper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default LanguageMenu;
