import { useMemo, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import axios from "axios";
import { toast } from "react-toastify";

import { storeAuthAction } from "@/store";
import { alertDialogService, loadingScreenOverlayService } from "@/services";
import { axiosHelpers, reduxHelpers } from "@/utils/helpers";

import { ClickAwayListener, Grow, Popper } from "@mui/material";
import AppMenuList from "@/components/AppMenuList";
import AppMenuItem from "@/components/AppMenuItem";
import AppPaper from "@/components/AppPaper";
import AppListItemText from "@/components/AppListItemText";
import AppButton from "@/components/AppButton";
import AppListItem from "@/components/AppListItem";
import AppLink from "@/components/AppLink";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "@/hooks";

import type { CancelTokenSource } from "axios";

const AccountMenu = () => {
  const signOutSourceRef = useRef<CancelTokenSource | null>(null);

  const router = useRouter();

  const dispatch = useAppDispatch();

  const $s_authAction = useMemo(
    () => bindActionCreators(storeAuthAction, dispatch),
    [dispatch]
  );

  const $s_authUser = useAppSelector((state) => state.auth.authUser);

  const [accountMenuPopperAnchorEl, setAccountMenuPopperAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const accountMenuPopperOpen = !!accountMenuPopperAnchorEl;

  const { t } = useTranslation();

  const handleAccountMenuPopperToggle = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAccountMenuPopperAnchorEl(event.currentTarget);
  };

  const handleAccountMenuPopperClose = () => {
    setAccountMenuPopperAnchorEl(null);
  };

  const handleSignOut = async () => {
    setAccountMenuPopperAnchorEl(null);
    const { isConfirmed } = await alertDialogService.fire({
      title: t("signOut"),
      content: t("areYouSureYouWantToSignOut"),
    });

    if (!isConfirmed) return;

    loadingScreenOverlayService.fire(t("pending"));

    signOutSourceRef.current = axios.CancelToken.source();

    const response = await reduxHelpers.callActionWithPromise(
      $s_authAction.signOutSaga,
      {
        cancelToken: signOutSourceRef.current.token,
      }
    );
    if (!!response.isCancelled) return;
    if (axiosHelpers.checkRequestSuccess(response)) {
      toast.success(t("signOutSuccessfully"));
      router.push(`/sign-in?redirect_path=${router.asPath}`);
    } else toast.error(response.message);

    loadingScreenOverlayService.close();
  };

  return (
    <>
      <AppButton
        borderRadius="circular"
        noWrap
        endIcon={<KeyboardArrowDownIcon fontSize="inherit" />}
        onClick={handleAccountMenuPopperToggle}
      >
        {$s_authUser?.name}
      </AppButton>
      <Popper
        open={accountMenuPopperOpen}
        anchorEl={accountMenuPopperAnchorEl}
        placement="bottom"
        transition
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 10 + 2],
            },
          },
        ]}
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "top right" }}>
            <AppPaper boxShadowVariant="menuPopper">
              <ClickAwayListener onClickAway={handleAccountMenuPopperClose}>
                <AppMenuList sx={{ paddingTop: "0px !important" }}>
                  <AppListItem divider>
                    <AppListItemText
                      primary={`${$s_authUser?.name}${
                        $s_authUser?.role?.display_name
                          ? ` (${$s_authUser?.role?.display_name})`
                          : ""
                      }`}
                      primaryTypographyProps={{
                        mb: 0.5,
                      }}
                      secondary={`@${$s_authUser?.username}`}
                    />
                  </AppListItem>
                  <AppMenuItem
                    component={AppLink}
                    href="/account/profile"
                    hoverColor="none"
                    onClick={handleAccountMenuPopperClose}
                  >
                    <AppListItemText>{t("myProfile")}</AppListItemText>
                  </AppMenuItem>
                  <AppMenuItem onClick={handleSignOut}>
                    <AppListItemText
                      primaryTypographyProps={{
                        color: "error.main",
                        fontWeight: 600,
                      }}
                    >
                      {t("signOut")}
                    </AppListItemText>
                  </AppMenuItem>
                </AppMenuList>
              </ClickAwayListener>
            </AppPaper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AccountMenu;
