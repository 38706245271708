import { useMemo } from "react";
import { bindActionCreators } from "redux";

import { storeCommonAction } from "@/store";
import { commonConstants } from "@/utils/constants";

import { AppBar, Box, useMediaQuery } from "@mui/material";
import AdminAppBarToolbar from "@/components/AdminAppBarToolbar";
import AppIconButton from "@/components/AppIconButton";
import AppTypography from "@/components/AppTypography";
import LanguageMenu from "@/layouts/AdminLayout/components/AdminAppBar/components/LanguageMenu";
import AccountMenu from "@/layouts/AdminLayout/components/AdminAppBar/components/AccountMenu";

import MenuIcon from "@mui/icons-material/Menu";

import { useAppDispatch, useAppSelector } from "@/hooks";

import useStyles from "./AdminAppBar.styles";
import NotificationNav from "@/layouts/AdminLayout/components/AdminAppBar/components/NotificationNav";

export type AdminBarProps = {
  title?: string;
  className?: string;
};

const MenuIconButton = () => {
  const { theme } = useStyles();

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useAppDispatch();

  const $s_commonAction = useMemo(
    () => bindActionCreators(storeCommonAction, dispatch),
    [dispatch]
  );

  const $s_adminSidebarCollapseOpened = useAppSelector(
    (state) => state.common.adminSidebarCollapseOpened
  );
  const $s_floatAdminSidebarOpened = useAppSelector(
    (state) => state.common.floatAdminSidebarOpened
  );

  const handleSidebarToggle = () => {
    if (isMdDown)
      $s_commonAction.setFloatAdminSidebarOpened(!$s_floatAdminSidebarOpened);
    else
      $s_commonAction.setAdminSidebarCollapseOpened(
        !$s_adminSidebarCollapseOpened
      );
  };

  return (
    <AppIconButton
      edge="start"
      color="common.darkNeutral"
      borderRadius="circular"
      onClick={handleSidebarToggle}
    >
      <MenuIcon />
    </AppIconButton>
  );
};

const AdminAppBar = (props: AdminBarProps) => {
  const { className, title } = props;

  const { classes, cx } = useStyles();

  return (
    <AppBar
      className={cx(classes.root, !!className && className)}
      position="fixed"
      elevation={0}
      color="inherit"
      id={commonConstants.ADMIN_HEADER_ELE_ID}
    >
      <AdminAppBarToolbar className={classes.adminAppBarToolbar}>
        <MenuIconButton />
        {title && (
          <AppTypography variant="titleSemi20" noWrap>
            {title}
          </AppTypography>
        )}
        <Box flexGrow={1} />
        <NotificationNav />
        <AccountMenu />
        <LanguageMenu />
      </AdminAppBarToolbar>
    </AppBar>
  );
};

export default AdminAppBar;
