import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "AdminAppBar",
})((theme) => {
  return {
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginLeft: 280,
      width: `calc(100% - ${280}px)`,
      backgroundColor: alpha(theme.palette.common.white, 0.8),
      backdropFilter: "blur(6px)",
      transition: theme.transitions.create(["width", "margin-left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        width: "100%",
      },
    },
    adminAppBarToolbar: {
      gap: theme.spacing(1.5),
    },
  };
});

export default useStyles;
