import { storeNotificationSelectors } from "@/store";

import AppBadge from "@/components/AppBadge";
import AppIconButton from "@/components/AppIconButton";
import AppLink from "@/components/AppLink";
import AppSvgIcon from "@/components/AppSvgIcon";

import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";

import { useAppSelector } from "@/hooks";

const NotificationNav = () => {
  const $s_notificationCountItem = useAppSelector(
    storeNotificationSelectors.selectNotificationCountItem
  );

  return (
    <AppIconButton
      edge="x"
      component={AppLink}
      href="/notifications"
      underline="none"
      borderRadius="circular"
      hoverColor="none"
    >
      <AppBadge
        badgeContent={$s_notificationCountItem?.unread}
        color="error.main"
      >
        <AppSvgIcon component={NotificationsIcon} fontSize="inherit" />
      </AppBadge>
    </AppIconButton>
  );
};

export default NotificationNav;
